



































import { Vue, Component, Prop, VModel, Ref } from 'vue-property-decorator'
import { QStepper } from 'quasar'
import Chevron from './Chevron.vue'

interface Step {
  name: number
  icon: string
  activeIcon: string
  doneIcon: string
  title: string
  caption: string
  component: Vue
}

@Component
export default class BaseStepper extends Vue {
  @VModel({
    required: true,
    type: Number,
  }) step!: number

  @Prop({
    required: true,
    type: Array,
  }) readonly steps!: Step[]

  @Ref() readonly stepper!: QStepper

  get calculatedSteps () {
    return this.steps.map(s => ({ ...s, done: this.step > s.name || this.isDone }))
  }

  private mounted () {
    const tabContainers = document.querySelectorAll('.q-stepper__tab')
    const count = tabContainers.length
    tabContainers.forEach((el, index) => {
      if (index + 1 === count) return
      const mountNode = document.createElement('div')
      mountNode.id = 'mount-node' + index
      el.appendChild(mountNode)
      const ChevronComp = Vue.extend(Chevron)
      new ChevronComp().$mount('#mount-node' + index)
    })
  }

  private isDone = false

  public next () {
    this.stepper.next()
  }

  public previous () {
    this.stepper.previous()
  }

  public goTo (panelName : string | number) {
    this.stepper.goTo(panelName)
  }

  private stepDone () {
    this.isDone = true
  }
}
