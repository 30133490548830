









import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
    IconChevronRight: () => import('@/components/UI/icons/IconChevronRight.vue'),
  },
})
export default class Chevron extends Vue {

}
